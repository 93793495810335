import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade } from 'src/app/core/store';

import { mapMunicipalities, getStates, defaultAnimation, setItem, StorageItem } from '../../../core';
import { Municipality } from 'src/app/core/models/class/municipality';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  ...defaultAnimation,
})
export class RegisterComponent implements OnInit {
  fieldTextType = false;
  mappedMunicipalities: Map<string, Array<Municipality>> = null;
  states: Set<string> = null;
  selectedState = null;
  expanded = new Array<boolean>();
  form;
  isLoading = false;

  years: number[] = [];

  constructor(
    private _toastLuncher: ToastLuncherService,
    public route: Router,
    public _munisFacade: MunisFacade,
    private userService: UserService,
    private _formBuilder: UntypedFormBuilder,
  ) {
    this.form = this._formBuilder.group({
      first_name: [null, [Validators.required, Validators.maxLength(25)]],
      last_name: [null, [Validators.required, Validators.maxLength(25)]],
      birth_date: [null, [Validators.required]],
      phone_number: [
        null,
        [Validators.required, Validators.maxLength(8), Validators.minLength(8), Validators.pattern('^[0-9]+$')],
      ],
      email: [null, [Validators.email]],
      municipality_id: [null, [Validators.required, Validators.pattern('^[0-9]+$')]],
      password: [null, [Validators.required, Validators.maxLength(25), Validators.minLength(5)]],
      password_verification: [null, [Validators.required, Validators.maxLength(25)]],
      consent: [false, Validators.required],
      gender: [null, Validators.required],
      city_name: [null, Validators.required],
    });
  }

  ngOnInit() {
    this.isLoading = false;
    this._toastLuncher.showMobileApp();
    this._munisFacade.munisList$.subscribe(res => {
      if (res) {
        this.states = getStates(res);
        this.mappedMunicipalities = mapMunicipalities(res);
      }
    });

    this.populateYearDropdown();
  }

  populateYearDropdown() {
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 100;
    const maxYear = currentYear - 12;

    this.years = [];
    for (let year = maxYear; year >= minYear; year--) {
      this.years.push(year);
    }
  }

  submit() {
    try {
      if (!this.areEqual('password', 'password_verification')) {
        throw new Error('تأكيد كلمة المرور يجب أن تكون مماثلة لكلمة المرور');
      }
      if (!this.form.valid) {
        throw new Error('الرجاء ملء كل الفراغات');
      }

      if (!this.form.get('consent').value) throw new Error('الرجاء الموافقة على شروط الاستخدام');
      const year = this.form.get('birth_date')?.value;
      const formattedDate = `${year}-01-01`;
      if (this.getAge(formattedDate) < 12) throw new Error('عمرك أقل من الحد المسموح به. !');

      this.isLoading = true;
      const formData = {
        ...this.form.value,
        birth_date: formattedDate,
      };

      this.userService.register(formData).subscribe(
        data => {
          setItem(StorageItem.phone_number, formData.phone_number.toString());
          setItem(StorageItem.municipality, formData.municipality_id.toString());
          this.route.navigateByUrl('/verification');
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          if (error?.error?.message === 'User with this phone number already exists') {
            this._toastLuncher.alert({
              text: 'هذا الحساب موجود.\nالرجاء تسجيل الدخول. يمكن أيضا استرجاع كلمة السر في حالة نسيانها.',
            });
          } else {
            this._toastLuncher.alert({ text: 'لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً' });
          }
        },
      );
    } catch (error) {
      this.isLoading = false;
      this._toastLuncher.alert({ text: error.message });
    }
  }

  getAge(birth_date: string) {
    const today = new Date();
    const birthDate = new Date(birth_date);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  isValidFormKey(key: string) {
    const formKey = this.form.controls[key];
    return formKey.valid && (formKey?.dirty || formKey?.touched);
  }

  areEqual(a: string, b: string) {
    return this.form.get(a)?.value === this.form.get(b)?.value;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
