import { Component, OnInit } from '@angular/core';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';

import { SubjectAccessRequest } from '../../../core/models/class/subject-access-request';
import { SubjectAccessRequestService } from '../../../services/subject-access-request.service';
import { defaultAnimation } from '../../../core';
import { UpdateTypes } from 'src/app/core/models/types';
import { OpsUpdateFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

@Component({
  selector: 'app-accesinfo',
  templateUrl: './accesinfo.component.html',
  styleUrls: ['./accesinfo.component.css'],
  ...defaultAnimation,
})
export class AccesinfoComponent extends OpsUpdateFeature<SubjectAccessRequest> implements OnInit {
  addNew = false;
  municipalityName: string;

  constructor(
    public _toastLuncher: ToastLuncherService,
    public _munisFacade: MunisFacade,
    public subjectAccessRequestService: SubjectAccessRequestService,
    public _userFacade: UserFacade,
    public _uiFacade: UiFacade,
  ) {
    super(subjectAccessRequestService, _userFacade, _munisFacade, _toastLuncher);
    this._uiFacade.setFeatureLoading();
  }

  ngOnInit() {}

  onMunicipalityChange(municipality: Municipality) {
    this.municipalityName = municipality.name_fr;
    this.items = [];
    this.filteredItems = [];
    this.getPages();
  }

  selectItem(item, i) {
    this.selectedItem = item;
    document.getElementById(`access-${i}`).toggleAttribute('hidden');
  }

  postSettingData() {
    this.filteredItems = this.items;
    this.setFilters();
    this._uiFacade.setFeatureLoaded();
  }

  preSettingData(data: SubjectAccessRequest[]): SubjectAccessRequest[] {
    return data
      .sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1))
      .map(item => {
        item.status = item.updates.length ? item.updates[0].status : UpdateTypes.RECEIVED;
        return item;
      });
  }
}
