// verification.component.ts
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { getItem, setItem, StorageItem } from 'src/app/core';
import { UserService } from 'src/app/core/services/user.service';
import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunicipalityService } from '../../../services/municipality.service';
import { MunisFacade } from 'src/app/core/store';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit, OnDestroy {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  seconds = 30;
  code: string;
  phone: string;
  mun: string;
  intervalId: any;

  constructor(
    private toast: ToastLuncherService,
    public router: Router,
    private userService: UserService,
    private jwtHelper: JwtHelperService,
    private utilsService: MunicipalityService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.phone = getItem(StorageItem.phone_number);
    this.mun = MunisFacade.getMunicipalityId();
    this.startTimer(); // Start timer when component initializes
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startTimer() {
    this.seconds = 30;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      if (this.seconds > 0) {
        this.seconds--;
        this.cdr.detectChanges();
      } else {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  getMunicipality() {
    this.utilsService.getById(this.mun).subscribe(data => {
      const route = data.route_name;
      window.location.href = '/' + route;
    });
  }

  resendCode() {
    if (this.seconds > 0) return; // Prevent resending if timer hasn't reached 0
    this.isLoading$.next(true);

    // Using the existing register/verify-otp endpoint with empty OTP to trigger resend
    this.userService.verifyOTP(this.phone, '').subscribe(
      () => {
        this.isLoading$.next(false);
        this.startTimer();
        this.toast.success({ text: 'تم إرسال رمز التفعيل مجددا', showConfirmButton: false });
      },
      error => {
        this.isLoading$.next(false);
        this.toast.alert({ text: 'لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً', showConfirmButton: false });
      },
    );
  }

  verify() {
    if (!this.code || this.code.length !== 6) {
      this.toast.info({ text: 'الرجاء التثبت من رمز التأكيد', showConfirmButton: false });
      return;
    }

    this.isLoading$.next(true);
    this.userService.verifyOTP(this.phone, this.code).subscribe(
      data => {
        setItem(StorageItem.token, data.access);
        setItem(StorageItem.refresh_token, data.refresh);
        setItem(StorageItem.connected_user_id, this.jwtHelper.decodeToken(data.access).user_id);
        setItem(StorageItem.first_login, 'true');
        this.isLoading$.next(false);
        this.getMunicipality();
      },
      error => {
        this.isLoading$.next(false);
        this.toast.alert({ text: 'رمز التفعيل غير صحيح', showConfirmButton: false });
      },
    );
  }
}
