import { Component, OnInit } from '@angular/core';

import { ToastLuncherService } from 'src/app/services/toast-luncher.service';
import { MunisFacade, UiFacade, UserFacade } from 'src/app/core/store';
import { Dossier } from '../../../core/models/class/dossier';
import { DossiersService } from '../../../services/dossiers.service';
import {
  dossierTypes,
  isValidCitizenId,
  isValidIdentifier,
  defaultAnimation,
  getItem,
  StorageItem,
} from '../../../core';
import { ArabicDossierTypes, DossierTypes, UpdateTypes, mode } from 'src/app/core/models/types';
import { GenericListFeature } from 'src/app/core/models/class/GenericListFeature';
import { Municipality } from 'src/app/core/models/class/municipality';

declare let $: any;
@Component({
  selector: 'app-dossiers-list',
  templateUrl: './dossiers-list.component.html',
  styleUrls: ['./dossiers-list.component.css'],
  ...defaultAnimation,
})
export class DossiersListComponent extends GenericListFeature<Dossier> implements OnInit {
  dossier: Dossier = new Dossier();
  unique_id = '';
  cin: number;
  showFollowForm: boolean = false;
  showNewPermitForm: boolean = false;
  selectedDossierIndex = -1;
  municipalityName = getItem(StorageItem.municipality_route_name);
  type = mode.MODE_DOSSIER;
  constructor(
    public _munisFacade: MunisFacade,
    public _userFacade: UserFacade,
    public _toastLuncher: ToastLuncherService,
    public dossiersService: DossiersService,
    public _uiFacade: UiFacade,
  ) {
    super(dossiersService, _userFacade, _munisFacade, _toastLuncher);
    this._uiFacade.setFeatureLoading();
  }

  ngOnInit() {}

  onMunicipalityChange(muni: Municipality) {
    if (this.isAuthenticated) {
      this.items = [];
      this.getPages();
    }
  }

  getPages() {
    this.isLoading = true;
    //@ts-ignore
    this._service.getFollowedItems().subscribe(
      data => {
        this.items = data.sort((item1, item2) => (item1.created_at > item2.created_at ? -1 : 1));
        this.items.forEach(dossier => {
          dossier.status = dossier.updates.length ? dossier.updates[0].status : UpdateTypes.RECEIVED;
          dossier.type = dossierTypes.filter(
            item => item.name === dossier.type || item.name === 'OTHER',
          )[0].displayName;

          dossier.type == ArabicDossierTypes.SONEDE ? (dossier.type = ArabicDossierTypes.NETWORKS) : null;
        });
        this.postSettingData();
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        throw new Error('لا يمكن الاتصال بالمنصة، الرجاء المحاولة لاحقاً');
      },
    );
  }

  postSettingData(): void {
    this._uiFacade.setFeatureLoaded();
  }

  followItem(unique_id, cin) {
    if (!unique_id || !isValidCitizenId(cin))
      throw new Error('الرجاء ملء كل الفراغات وإدخال 3 أرقام الأخيرة (من اليمين) من رقم بطاقة التعريف الوطنية');
    if (!isValidIdentifier(unique_id)) throw new Error('المعرف الوحيد لا يمكن ان يقل عن 3 رموز');

    this.dossiersService
      .followItem({
        unique_identifier: unique_id,
        cin_digits: cin.toString(),
      })
      .subscribe(
        data => {
          this.dossier = data;
          this.dossier.type = dossierTypes.filter(
            item => item.name === this.dossier.type || item.name === DossierTypes.OTHER,
          )[0].displayName;
          this.dossier.status = this.dossier.updates.length ? this.dossier.updates[0].status : UpdateTypes.RECEIVED;
          this.items.unshift(this.dossier);
          this.cin = null;
          this.unique_id = null;
        },
        error => {
          throw new Error('الرجاء التثبت من المعرف ورقم بطاقة التعريف الوطنية');
        },
      );
  }

  onSuccess($event) {
    const { unique_id, cin } = $event;
    this.followItem(unique_id, cin);
    this.showFollowForm = false;
    this.showNewPermitForm = false;
  }

  selectDossier(item, i) {
    if (i === this.selectedDossierIndex) {
      this.selectedDossierIndex = -1;
    } else {
      this.dossier = item;
      this.selectedDossierIndex = i;
    }
    // document.getElementById(`dossier-${i}`).toggleAttribute('hidden');
  }

  /**
   * set all forms show to 'false'
   */
  closeForms() {
    this.showFollowForm = false;
    this.showNewPermitForm = false;
  }
}
